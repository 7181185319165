<div class="content_main-container">
  <ng-container *ngrxLet="selectedFI$ as selectedFI">
    <div class="fi-redirect" *ngIf="selectedFI">
      <!-- <div class="flex flex-row fi-row" aria-hidden="true">
        <pbb-svg-icon id="konek-logo" [icon]="'konek-logo'" alt="konek"></pbb-svg-icon>
        <img
        id="fi-link-logo"
        alt="{{ 'AUTH_REDIRECT.FI_LINK_LOGO' | translate}}"
        src="assets/images/account_linked.png"
        width="30"
      />
        <img
          id="fi-logo"
          pbbImgError="assets/images/bank.png"
          [alt]="selectedFI?.display_name"
          [src]="selectedFI?.logo | base64 | async"
          class="fi-logo"
        />
      </div> -->
      <h1 id="redirect-page-title" class="mb-8 text-dark">{{ title | translate }}</h1>
      <p id="redirect-page-subtitle" class="mat-body-2 mb-24 text-grey-subtitle">
        {{ subtitle | translate }}
      </p>
    </div>
    <div class="fi-redirect-info-list">
    <pbb-info-list [list]="infoList" class="flex-row ml-24 mr-24 mt-24 mb-24"></pbb-info-list>
    </div>
    <button id="continue-redirect" mat-flat-button color="primary" class="mt-24 mb-16" (click)="continue()">
      {{ 'AUTH_REDIRECT.CONTINUE' | translate : { fi: selectedFI.display_name } }}
    </button>
    <small id="continue-redirect-notice" class="mat-body-2 text-center block">
      {{ 'AUTH_REDIRECT_NOTICE' | translate }}
    </small>

  </ng-container>
</div>
